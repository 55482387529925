<template>
  <AdminTemplate class="admin-register-list">
    <div class="admin-header">
      <h1>ข้อมูลการจองที่พัก</h1>
    </div>
    <el-row :gutter="6">
      <el-col :span="10">
        <el-card class="admin-search-box">
          <el-form :model="form" ref="form">
            <h3>ค้นหาข้อมูลการจองที่พัก</h3>
            <el-row :gutter="12">
              <el-col :span="12">
                <el-form-item label="ค้นหา" prop="search">
                  <el-input
                    v-model="form.search"
                    placeholder="ค้นหาจากชื่อ,นามสกุล,รหัสลงทะเบียน"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="สถานะ" prop="status">
                  <el-select
                    v-model="form.status"
                    placeholder="สถานะ"
                    class="width-100"
                  >
                    <div slot="prefix">
                      <i
                        class="fa-solid fa-circle"
                        :style="`color:${getStatusColor(form.status)};`"
                      ></i>
                    </div>
                    <el-option
                      v-for="item in reservationStatus"
                      :key="item.name"
                      :label="item.label"
                      :value="item.label"
                    >
                      <span
                        ><i
                          class="fa-solid fa-circle"
                          :style="`color:${item.color};margin-right:5px`"
                        ></i>
                        {{ item.label }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div class="float-right">
                  <el-button
                    @click="search"
                    :loading="loadingList"
                    type="success"
                    size="medium"
                    >ค้นหา</el-button
                  >
                </div></el-col
              >
            </el-row>
          </el-form>
        </el-card>

        <div class="admin-list-box">
          <el-card>
            <h3>รายการลงทะเบียน</h3>
            <div v-loading="loadingList" class="user-list">
              <el-row
                :gutter="20"
                v-for="item in users"
                :key="item.id"
                class="user-item"
                @click="fetchReservationDetail(item.reservation_code)"
              >
                <el-col :span="19" class="content-box">
                  <div class="content-detail-box">
                    <i
                      :title="item.status"
                      class="fa-solid fa-circle"
                      :style="`color:${getReservationStatusColor(
                        item.status
                      )};`"
                    ></i>
                    <div>
                      <p>
                        {{
                          `${item.prefix_en}${item.first_name_en} ${item.last_name_en}`
                        }}
                      </p>
                      <p>
                        {{
                          `${item.prefix}${item.first_name} ${item.last_name}`
                        }}
                      </p>
                      <p>
                        {{ item.create_date | dateTHShort }}
                        <el-tag type="info">{{ item.reservation_code }}</el-tag>
                      </p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5" class="action-box">
                  <el-button
                    @click="fetchReservationDetail(item.reservation_code)"
                    type="info"
                    icon="el-icon-user-solid"
                    size="mini"
                    circle
                  ></el-button>
                </el-col>
              </el-row>
              <div v-if="total > 0" class="pagination-box">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :current-page.sync="page"
                  @current-change="handleCurrentChange"
                  :page-size="limit"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col v-if="detail" v-loading="loadingDetail" :span="14">
        <el-row :gutter="12"
          ><el-col :span="12">
            <el-card class="user-detail-box">
              <h3>ข้อมูลผู้จอง : #{{ detail.reservation.reservation_code }}</h3>
              <p>
                {{
                  `${detail.reservation.prefix_en} ${detail.reservation.first_name_en} ${detail.reservation.last_name_en}`
                }}
              </p>
              <p>
                {{
                  `${detail.reservation.prefix} ${detail.reservation.first_name} ${detail.reservation.last_name}`
                }}
              </p>
              <el-divider></el-divider>

              <p>
                <b>บ้าน:</b>
                {{
                  detail.reservation.address_type === "home" ? "บ้าน" : "ออฟฟิศ"
                }}
              </p>
              <div class="display-flex">
                <p class="flex-1">
                  <b>District:</b> {{ detail.reservation.district }}
                </p>
                <p class="flex-1">
                  <b>Amphoe:</b> {{ detail.reservation.amphoe }}
                </p>
              </div>
              <div class="display-flex">
                <p class="flex-1"><b>City:</b> {{ detail.reservation.city }}</p>
                <p class="flex-1">
                  <b>Country:</b> {{ detail.reservation.country }}
                </p>
              </div>
              <div class="display-flex">
                <p class="flex-1">
                  <b>Zip:</b> {{ detail.reservation.zipcode }}
                </p>
                <p class="flex-1">
                  <b>Telephone:</b> {{ detail.reservation.telephone }}
                </p>
              </div>
              <p><b>Email:</b> {{ detail.reservation.email }}</p>
              <p>
                <b>หน่วยงาน/องค์กร/บริษัท:</b>
                {{ detail.reservation.institution }}
              </p>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="payment-detail-box"
              ><h3>{{ detail.reservationInfo.reservation_code }}</h3>
              <div class="payment-detail-content reservation">
                <div
                  v-if="
                    detail.reservationInfo.status !==
                    'ยังไม่ได้ยื่นหลักฐานการชำระเงิน'
                  "
                  class="image-box"
                >
                  <a
                    data-fancybox="payment-preview"
                    :href="`${fileUploadURL}/${detail.reservationInfo.img}`"
                    class="fancybox"
                    ><el-card class="box-card" shadow="hover">
                      <el-image
                        :src="`${fileUploadURL}/${detail.reservationInfo.img}`"
                        fit="cover"
                      ></el-image>
                    </el-card>
                  </a>
                </div>

                <div class="data-box">
                  <div>
                    <p>
                      <b>สถานะ: </b>
                    </p>
                    <el-form :model="formUpdate" ref="form-update">
                      <el-form-item prop="status">
                        <el-select
                          v-model="formUpdate.status"
                          placeholder="สถานะ"
                          class="width-100"
                          @change="
                            (status) =>
                              updateReservationStatus(
                                detail.reservation.reservation_code,
                                status
                              )
                          "
                        >
                          <div slot="prefix">
                            <i
                              class="fa-solid fa-circle"
                              :style="`color:${getReservationStatusColor(
                                formUpdate.status
                              )};`"
                            ></i>
                          </div>
                          <el-option
                            v-for="item in reservationStatusAction"
                            :key="item.name"
                            :label="item.label"
                            :value="item.label"
                          >
                            <span
                              ><i
                                class="fa-solid fa-circle"
                                :style="`color:${item.color};margin-right:5px`"
                              ></i>
                              {{ item.label }}</span
                            >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </div>

                  <div class="data-detail">
                    <h3>ข้อมูลการชำระเงิน</h3>
                    <p>
                      <b>เลขใบ Payment:</b> #{{
                        detail.reservation.reservation_code
                      }}
                    </p>
                    <h4>วิธีการชำระเงิน</h4>
                    <p>
                      <i class="fa-regular fa-circle-check"></i>
                      โอนเงินเข้าบัญชี
                    </p>

                    <p>
                      <b>จำนวนเงิน:</b>
                      {{ totalPrice | comma }} บาท
                    </p>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import { status } from "@/maxins/status";
import { auth } from "@/maxins/auth";
import { HTTP } from "@/plugins/axios.js";

export default {
  name: "AdminReservation",
  components: {
    AdminTemplate,
  },
  props: {
    msg: String,
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.paymentList.map((item) => {
        let sum =
          item.singlePrice * item.singleNumber +
          item.twinPrice * item.twinNumber;

        total += sum;
      });

      return total;
    },
  },
  mixins: [status, auth],
  data() {
    return {
      form: {
        search: "",
        status: "ทั้งหมด",
      },
      formUpdate: {
        status: "",
      },
      users: [],
      total: 0,
      limit: 10,
      page: 1,
      loadingList: true,
      loadingDetail: true,
      loadingExport: false,
      loadingExportGroup: false,
      detail: null,
      fileExportURL: process.env.VUE_APP_BASE_EXPORT_FILE,
      fileUploadURL: process.env.VUE_APP_BASE_UPLOAD_FILE,
      paymentList: [
        {
          id: "superior",
          name: "Superior Room",
          singlePrice: 2000,
          twinPrice: 2000,
          singleNumber: 0,
          twinNumber: 0,
        },
        {
          id: "deluxe",
          name: "Deluxe Room",
          singlePrice: 2600,
          twinPrice: 2600,
          singleNumber: 0,
          twinNumber: 0,
        },
        {
          id: "keyMan",
          name: "Key man Room",
          singlePrice: 3200,
          twinPrice: 3200,
          singleNumber: 0,
          twinNumber: 0,
        },
        {
          id: "extraBed",
          name: "Extra Bed",
          singlePrice: 1000,
          twinPrice: 1000,
          singleNumber: 0,
          twinNumber: 0,
        },
      ],
    };
  },
  mounted() {
    this.fetchReservation();
  },
  methods: {
    search() {
      this.loadingList = true;
      this.page = 1;
      this.fetchReservation();
    },
    handleCurrentChange(val) {
      this.loadingList = true;
      this.page = val;
      this.fetchReservation();
    },
    async fetchReservation() {
      try {
        let obj = {
          search: this.form.search,
          status: this.form.status === "ทั้งหมด" ? "" : this.form.status,
          page: this.page,
          limit: this.limit,
        };
        let res = await HTTP.post(`/reservation/get`, obj);

        if (res.data.success) {
          this.total = res.data.data.total;
          this.users = res.data.data.result;
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.logout();
        }
      } finally {
        this.loadingList = false;
      }
    },
    async fetchReservationDetail(code) {
      this.loadingDetail = true;
      let res = await HTTP.get(`/reservation/get/${code}`);

      if (res.data.success) {
        this.detail = res.data.data;

        this.formUpdate.status = this.detail.reservationInfo.status;
        this.paymentList = [
          {
            id: "superior",
            name: "Superior Room",
            singlePrice: 2000,
            twinPrice: 2000,
            singleNumber: res.data.data.reservationInfo.superior_room,
            twinNumber: res.data.data.reservationInfo.superior_twin_room,
          },
          {
            id: "deluxe",
            name: "Deluxe Room",
            singlePrice: 2600,
            twinPrice: 2600,
            singleNumber: res.data.data.reservationInfo.deluxe_room,
            twinNumber: res.data.data.reservationInfo.deluxe_twin_room,
          },
          {
            id: "keyMan",
            name: "Key man Room",
            singlePrice: 3200,
            twinPrice: 3200,
            singleNumber: res.data.data.reservationInfo.key_man_room,
            twinNumber: res.data.data.reservationInfo.key_man_twin_room,
          },
          {
            id: "extraBed",
            name: "Extra Bed",
            singlePrice: 1000,
            twinPrice: 1000,
            singleNumber: res.data.data.reservationInfo.extra_bed,
            twinNumber: res.data.data.reservationInfo.extra_twin_bed,
          },
        ];
      }

      this.loadingDetail = false;
    },
    async deleteReservation(id) {
      this.loadingList = true;
      let res = await HTTP.delete(`/register/by/${id}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchReservation();
      }

      this.loadingList = false;
    },
    async updateReservationStatus(reservation_code, status) {
      this.loadingList = true;
      this.loadingDetail = true;

      let obj = {
        reservation_code: reservation_code,
        status: status,
      };

      let res = await HTTP.put(`/reservation/status`, obj);

      if (res.data.success) {
        this.$message({
          message: "อัปเดตสถานะสำเร็จ",
          type: "success",
        });
        this.detail = null;
        this.page = 1;
        this.fetchReservation();
        this.fetchReservationDetail(reservation_code);
      }

      this.loadingDetail = false;
      this.loadingList = false;
    },
  },
};
</script>
