<template>
  <section id="contact-us" class="page-box">
    <h1 class="title">{{ $t("menu.contactUs") }}</h1>
    <div class="detail">
      <p>
        Division of Nephrology , Department of Medicine , Faculty of Medicine ,
        Chiang Mai University
      </p>
      <p>Tel : 053-936452 , 089-4331363 Fax : 053-895235</p>
      <p>E-mail : admin@rb2024.net</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactUsComponent",
  props: {
    msg: String,
  },
};
</script>
