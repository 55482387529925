<template>
  <section id="home" class="page-box">
    <el-image :src="require('@/assets/images/slide.png')"></el-image>
  </section>
</template>

<script>
export default {
  name: "HomeComponent",
  props: {
    msg: String,
  },
};
</script>
