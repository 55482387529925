<template>
  <section id="register" class="page-box">
    <h1 class="title">{{ $t("menu.register") }}</h1>
    <el-row :gutter="20" class="register-type">
      <el-col :sm="24" :md="12"
        ><el-card class="box-card" shadow="hover">
          <i class="el-icon-user-solid"></i>
          <p>{{ $t("register.personal") }}</p>
          <el-button @click="$router.push(`/register/single`)" type="primary">{{
            $t("message.btnRegister")
          }}</el-button>
        </el-card></el-col
      >
      <el-col :sm="24" :md="12">
        <el-card class="box-card" shadow="hover">
          <i class="el-icon-office-building"></i>
          <p>{{ $t("register.organization") }}</p>
          <el-button @click="$router.push(`/register/group`)" type="primary">{{
            $t("message.btnRegister")
          }}</el-button>
        </el-card></el-col
      >
    </el-row>
    <el-row class="register-search">
      <el-row :gutter="20"
        ><el-col :sm="24" :md="12">
          <el-button @click="searchPaymentInvoice" type="primary">{{
            $t("register.findPaymentInvoice")
          }}</el-button></el-col
        ><el-col :sm="24" :md="12">
          <el-button @click="searchSubmitProof" type="success">{{
            $t("register.submitProofOfPayment")
          }}</el-button></el-col
        ></el-row
      >
    </el-row>
  </section>
</template>

<script>
export default {
  name: "RegisterComponent",
  props: {
    msg: String,
  },
  methods: {
    searchPaymentInvoice() {
      this.$prompt("", this.$t("register.fillFindPaymentInvoice"), {
        confirmButtonText: this.$t("message.btnSearch"),
        cancelButtonText: this.$t("message.btnCancel"),
        inputPattern: /[\w]+/,
        inputErrorMessage: "Please fill  Payment invoice code",
      })
        .then(({ value }) => {
          this.$router.push(`/payment/${value}`);
        })
        .catch(() => {});
    },
    searchSubmitProof() {
      this.$prompt("", this.$t("register.fillSubmitProofOfPayment"), {
        confirmButtonText: this.$t("message.btnSearch"),
        cancelButtonText: this.$t("message.btnCancel"),
        inputPattern: /[\w]+/,
        inputErrorMessage: "Please fill Submit proof of payment",
      })
        .then(({ value }) => {
          this.$router.push(`/payment-confirm?code=${value}`);
        })
        .catch(() => {});
    },
  },
};
</script>
