import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "@/assets/styles/main.scss";
import router from "./router";
import numeral from "numeral";
import locale from "element-ui/lib/locale/lang/en";
import ThaiBaht from "thai-baht-text";
import moment from "moment";
import momentTH from "moment/src/locale/th";
import store from "./store";
import i18nMessages from "@/plugins/i18nMessages";
import VueI18n from "vue-i18n";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(ElementUI, { locale });

Vue.filter("comma", (value) => {
  return numeral(value).format("0,0.[00]");
});

Vue.filter("commaBath", (value) => {
  return `฿${numeral(value).format("0,0.[00]")}`;
});

Vue.filter("commaDecimal", (value) => {
  return numeral(value).format("0,0.00");
});

Vue.filter("thaiBaht", (value) => {
  return ThaiBaht(value);
});

Vue.filter("dateNumber", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH)).format("L");
  }
  return res;
});

Vue.filter("dateTHShort", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH)).format("ll");
  }
  return res;
});

// Ready translated locale messages
const messages = i18nMessages;

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "th", // set locale
  messages, // set locale messages
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
