<template>
  <div class="admin-login">
    <el-container>
      <el-row :gutter="12">
        <el-card shadow="always" class="login-box">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            @submit.prevent="login('form')"
          >
            <el-form-item>
              <h1>เข้าสู่ระบบ</h1>
            </el-form-item>
            <el-form-item label="อีเมล" prop="email">
              <el-input v-model="form.email">
                <i slot="prefix" class="el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="รหัสผ่าน" prop="password">
              <el-input v-model="form.password" type="password" show-password>
                <i slot="prefix" class="el-icon-lock"></i
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="success"
                @click="login('form')"
                :loading="loading"
                native-type="submit"
                >เข้าสู่ระบบ</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
    </el-container>
  </div>
</template>

<script>
import { HTTP } from "@/plugins/axios.js";

export default {
  name: "AdminLogin",
  components: {},
  props: {
    msg: String,
  },
  data() {
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Please input email"));
      } else {
        let regx = /^[\wก-ฮ-.]+@([\wก-ฮ-]+.)+[\wก-ฮ-]{2,4}$/g;
        let match = regx.test(value);
        if (match) {
          callback();
        } else {
          callback(new Error("Please input correct email"));
        }
      }
    };
    return {
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please input Email",
            trigger: "blur",
          },
          { validator: checkEmail, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "Please input Password",
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let res = await HTTP.post(`/user/login`, this.form);

          if (res.data.success) {
            this.$message({
              message: "เข้าสู่ระบบสำเร็จ",
              type: "success",
            });
            this.$store.commit("setToken", res.data.data);
            setTimeout(() => {
              this.$router.push("admin-dashboard");
            }, 500);
          } else {
            this.$message({
              message: "รหัสผ่านไม่ถูกต้อง กรุณากรอกรหัสผ่านใหม่",
              type: "error",
            });
          }

          this.loading = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
