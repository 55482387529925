<template>
  <AdminTemplate>
    <el-row :gutter="12" class="admin-dashboard">
      <el-col :span="12">
        <el-card shadow="hover" class="dashboard-item">
          <i class="fa-solid fa-address-card"></i>
          <p class="dashboard-item-title">ข้อมูลการลงทะเบียน</p>

          <p class="dashboard-total">
            จำนวนผู้ลงทะเบียนสำเร็จ
            <el-tag type="success" effect="dark" size="small">
              {{ staticData ? staticData.totalRegister : 0 }}
            </el-tag>
          </p>
          <div>
            <el-button @click="$router.push('/admin-registered')" type="info"
              >ดูข้อมูล</el-button
            >
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="hover" class="dashboard-item">
          <i class="fa-solid fa-clipboard-check"></i>
          <p class="dashboard-item-title">ยืนยันการชำระเงิน</p>
          <p class="dashboard-total">
            จำนวนการยืนยันการชำระเงิน
            <el-tag type="primary" effect="dark" size="small">
              {{ staticData ? staticData.totalPayment : 0 }}
            </el-tag>
          </p>
          <div>
            <el-button
              @click="$router.push('/admin-payment-confirm')"
              type="info"
              >ดูข้อมูล</el-button
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import { HTTP } from "@/plugins/axios.js";
import { auth } from "@/maxins/auth";

export default {
  name: "AdminDashboard",
  components: {
    AdminTemplate,
  },
  props: {
    msg: String,
  },
  mixins: [auth],
  data() {
    return {
      staticData: null,
      loading: true,
    };
  },
  mounted() {
    this.fetchStatic();
  },
  methods: {
    async fetchStatic() {
      try {
        let res = await HTTP.get(`/dashboard/get-static`);

        if (res.data.success) {
          this.staticData = res.data.data;
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.logout();
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
